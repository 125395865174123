export const style = {
    'sources': {
        'tiles': {
            'type': 'vector',
            'tiles': [`${location.origin}/tiles/mvt/{z}/{x}/{y}`.replace(':1234', ':5000')],
            'layers': {
                'plots-fill': {
                    'id_name': 'properties.id_dzialki',
                    'area': {
                        'name': 'area_ha',
                        'type': 'ha',
                    },
                    'attributes': {
                        'area_ha_korytarze': 'Pow. bez k. ekologicznych',
                        'area_ha_lasy': 'Pow. bez lasów',
                        'area_ha_pn': 'Pow. bez p. narodowych',
                        'area_ha_pk': 'Pow. bez p. krajobrazowych',
                        'area_ha_soo': 'Pow. bez SOO',
                        'area_ha_oso': 'Pow. bez OSO',
                        'area_ha_kowr': 'Pow. bez KOWR',
                        'area_ha_amw': 'Pow. bez AMW'
                    },
                    'name': 'Działki',
                    'source-layer': 'dzialki_polygon',
                    'type': 'fill',
                    'paint': {
                        'fill-color': [
                            'case',
                            ["case", ["==", ["get", "area_ha_korytarze"], null], true, ["==", ["get", "area_ha_korytarze"], 0], true, ["==", ["get", "area_ha_lasy"], null], true, ["==", ["get", "area_ha_lasy"], 0], true, ["==", ["get", "area_ha_pn"], null], true, ["==", ["get", "area_ha_pn"], 0], true, ["==", ["get", "area_ha_pk"], null], true, ["==", ["get", "area_ha_pk"], 0], true, ["==", ["get", "area_ha_soo"], null], true, ["==", ["get", "area_ha_soo"], 0], true, ["==", ["get", "area_ha_oso"], null], true, ["==", ["get", "area_ha_oso"], 0], true, ["==", ["get", "area_ha_kowr"], null], true, ["==", ["get", "area_ha_kowr"], 0], true, ["==", ["get", "area_ha_amw"], null], true, ["==", ["get", "area_ha_amw"], 0], true, false],
                            'red',
                            '#000000'
                        ],
                        'fill-opacity': [
                            'case',
                            ['boolean', ['feature-state', 'hover'], false],
                            0.5,
                            0.1
                        ]
                    }
                },
                'plots-line': {
                    'id_name': 'id',
                    'area': null,
                    'attributes': null,
                    'name': 'Działki kontur',
                    'source-layer': 'dzialki_polygon',
                    'type': 'line',
                    'paint': {
                        'line-width': [
                            'case',
                            ['boolean', ['feature-state', 'hover'], false],
                            3,
                            0.5
                        ]
                    }
                },
                'buildings-fill': {
                    'id_name': 'id',
                    'area': {
                        'name': 'area_m2',
                        'type': '㎡',
                    },
                    'attributes': null,
                    'name': 'Budynki',
                    'source-layer': 'osm_buildings',
                    'type': 'fill',
                    'paint': {
                        'fill-color': '#000000',
                        'fill-opacity': [
                            'case',
                            ['boolean', ['feature-state', 'hover'], false],
                            0.5,
                            0.1
                        ]
                    }
                },
                'buildings-line': {
                    'id_name': 'id',
                    'area': null,
                    'attributes': null,
                    'name': 'Budynki kontur',
                    'source-layer': 'osm_buildings',
                    'type': 'line',
                    'paint': {
                        'line-width': [
                            'case',
                            ['boolean', ['feature-state', 'hover'], false],
                            3,
                            0.5
                        ]
                    }
                },
                'power-lines-low': {
                    'id_name': 'id',
                    'area': null,
                    'attributes': null,
                    'name': 'Linie energetyczne o niskim napięciu',
                    'source-layer': 'power_lines_low',
                    'type': 'line',
                    'paint': {
                        'line-color': '#F8DE22',
                        'line-width': 2
                    }
                },
                'power-lines-mid': {
                    'id_name': 'id',
                    'area': null,
                    'attributes': null,
                    'name': 'Linie energetyczne o średnim napięciu',
                    'source-layer': 'power_lines_mid',
                    'type': 'line',
                    'paint': {
                        'line-color': '#F94C10',
                        'line-width': 2
                    }
                },
                'power-lines-high': {
                    'id_name': 'id',
                    'area': null,
                    'attributes': null,
                    'name': 'Linie energetyczne o wysokim napięciu',
                    'source-layer': 'power_lines_high',
                    'type': 'line',
                    'paint': {
                        'line-color': '#C70039',
                        'line-width': 2
                    }
                },
                'power-lines-highest': {
                    'id_name': 'id',
                    'area': null,
                    'attributes': null,
                    'name': 'Linie energetyczne o najwyższym napięciu',
                    'source-layer': 'power_lines_highest',
                    'type': 'line',
                    'paint': {
                        'line-color': '#900C3F',
                        'line-width': 2
                    }
                },
                'buildings-buffer-fill': {
                    'id_name': 'id',
                    'area': null,
                    'attributes': null,
                    'name': 'Bufor 700m od budynków',
                    'source-layer': 'osm_buildings_buffer_700_final',
                    'type': 'fill',
                    'paint': {
                        'fill-color': '#900C3F',
                        'fill-opacity': 0.1
                    },
                    'layout': {
                        'visibility': 'none'
                    }
                },
            }
        }
    },
    'hover': [
        'plots-fill',
        'buildings-fill',
        'power-lines-low',
        'power-lines-mid',
        'power-lines-high',
        'power-lines-highest'
    ]
}